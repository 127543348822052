"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditAppDisclosures = exports.ConsentDisclosure = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var getTenantDisclosures_1 = require("@digital-motors-boatyard/common/dist/helpers/getTenantDisclosures");
var interfaces_1 = require("@digital-motors-boatyard/common/dist/interfaces");
var ui_checkbox_1 = require("@digital-motors-boatyard/ui.checkbox");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants");
var DealSheet_1 = require("../../context/DealSheet");
var Tenant_1 = require("../../context/Tenant");
var color_1 = require("../../lib/color");
var Disclosure_1 = require("../Disclosure");
var constants_2 = require("./finance-plus/constants");
var Disclaimer = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  margin-top: ", ";\n  text-align: ", ";\n\n  p + p {\n    margin-top: ", " !important;\n  }\n"], ["\n  color: ", ";\n  margin-top: ", ";\n  text-align: ", ";\n\n  p + p {\n    margin-top: ", " !important;\n  }\n"])), (0, color_1.color)('subtle'), ui_theme_1.spacing.xs, function (props) { return (props.center ? 'center' : 'left'); }, ui_theme_1.spacing.xs);
Disclaimer.defaultProps = {
    as: 'div',
    variant: 'caption',
};
// ConsentDisclosure and Disclaimer are used in web-client as well.
// We need to make sure codes are consistent until we move these out
// to somewhere common.
exports.ConsentDisclosure = (0, ui_theme_1.styled)(Disclaimer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 8fr 92fr;\n"], ["\n  display: grid;\n  grid-template-columns: 8fr 92fr;\n"])));
var CreditAppDisclosures = function (_a) {
    var onUpdate = _a.onUpdate;
    var tenant = (0, Tenant_1.useTenant)();
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var isFinance = dealSheet.financeType === constants_1.FINANCE;
    var consentCreditAppDisclosures = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (((_b = (_a = tenant.creditAppProviderConfig) === null || _a === void 0 ? void 0 : _a.creditAppProvider) === null || _b === void 0 ? void 0 : _b.providerApi) ==
            interfaces_1.CreditAppProviderApi.FINANCE_PLUS) {
            return constants_2.FINANCE_PLUS_CREDIT_APP_CONSENT_DISCLOSURES;
        }
        else {
            return (0, getTenantDisclosures_1.getTenantDisclosures)(enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION, tenant, dealSheet);
        }
    }, [tenant, dealSheet]);
    var creditAppOptionalDisclosures = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (((_b = (_a = tenant.creditAppProviderConfig) === null || _a === void 0 ? void 0 : _a.creditAppProvider) === null || _b === void 0 ? void 0 : _b.providerApi) ==
            interfaces_1.CreditAppProviderApi.FINANCE_PLUS) {
            return constants_2.FINANCE_PLUS_CREDIT_APP_OPTIONAL_DISCLOSURES.map(function (d) { return (__assign(__assign({}, d), { description: "".concat(d.description, " (optional)") })); });
        }
        else {
            return [];
        }
    }, [tenant]);
    var creditAppDisclosures = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (((_b = (_a = tenant.creditAppProviderConfig) === null || _a === void 0 ? void 0 : _a.creditAppProvider) === null || _b === void 0 ? void 0 : _b.providerApi) ==
            interfaces_1.CreditAppProviderApi.FINANCE_PLUS) {
            return constants_2.FINANCE_PLUS_CREDIT_APP_DISCLOSURES;
        }
        else {
            return (0, getTenantDisclosures_1.getTenantDisclosures)(enums_1.DisclosureLocation.CREDIT_APPLICATION, tenant, dealSheet).filter(function (d) {
                return !d.location.includes(enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION);
            });
        }
    }, [tenant, dealSheet]);
    var _b = (0, react_1.useState)(0), consented = _b[0], setConsented = _b[1];
    var updateRequiredCheckbox = (0, react_1.useCallback)(function (target) {
        target.checked
            ? setConsented(consented + 1)
            : setConsented(consented - 1);
    }, [consented]);
    (0, react_1.useEffect)(function () {
        if (isFinance && consented !== consentCreditAppDisclosures.length) {
            onUpdate('Please read and check the above checkboxes to proceed.');
        }
        else {
            onUpdate(null);
        }
    }, [isFinance, consentCreditAppDisclosures, consented, onUpdate]);
    if (!isFinance) {
        return (react_1.default.createElement(Disclaimer, { center: true },
            react_1.default.createElement("p", null, "All units are subject to prior sale.")));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(exports.ConsentDisclosure, { "data-testid": "CreditApp__CreditAppConsentDisclosure" },
            consentCreditAppDisclosures.map(function (d, i) { return (react_1.default.createElement(react_1.Fragment, { key: i },
                d.location.includes(enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION) && (react_1.default.createElement(ui_checkbox_1.Checkbox, { "data-testid": "consentCheckbox__".concat(i), value: "true", label: "", required: true, onChange: function (e) { return updateRequiredCheckbox(e.target); } })),
                react_1.default.createElement(Disclosure_1.Disclosure, { location: enums_1.DisclosureLocation.CREDIT_APPLICATION, "data-testid": "consentDisclosure__".concat(i), disclosure: d }))); }),
            creditAppOptionalDisclosures.map(function (d, i) { return (react_1.default.createElement(react_1.Fragment, { key: i },
                d.location.includes(enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION) && (react_1.default.createElement(ui_checkbox_1.Checkbox, { "data-testid": "consentOptionalCheckbox__".concat(i), value: "true", label: "", name: d.name })),
                react_1.default.createElement(Disclosure_1.Disclosure, { location: enums_1.DisclosureLocation.CREDIT_APPLICATION, "data-testid": "consentOptionalDisclosure__".concat(i), disclosure: d }))); })),
        react_1.default.createElement(Disclaimer, { "data-testid": "CreditApp__CreditAppDisclosure" }, creditAppDisclosures === null || creditAppDisclosures === void 0 ? void 0 : creditAppDisclosures.map(function (d, i) { return (react_1.default.createElement(Disclosure_1.Disclosure, { key: "disclosure__".concat(i), location: enums_1.DisclosureLocation.CREDIT_APPLICATION, "data-testid": "disclosure__".concat(i), disclosure: d })); }))));
};
exports.CreditAppDisclosures = CreditAppDisclosures;
var templateObject_1, templateObject_2;
