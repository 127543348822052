import { InventoryVesselFull } from '@digital-motors-boatyard/common/dist';
import { Country } from '@digital-motors-boatyard/common/dist/enums';
import {
  DealerInterface,
  DealSheet,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { getApplicableFees } from '@digital-motors-boatyard/common-frontend/dist/utility/pricing';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';

import { getVesselAssociations } from '../api/getVesselAssociations';
import { useAppData } from '../context/AppDataContext';
import { getOrCreateDealSheet } from '../context/DealSheet/utils/getOrCreateDealSheet';
import { getPartialDealSheetUpdateFromDealer } from '../context/DealSheet/utils/getPartialDealSheetUpdateFromDealer';
import { getPartialDealSheetUpdateFromTenant } from '../context/DealSheet/utils/getPartialDealSheetUpdateFromTenant';
import { getPartialDealSheetUpdateFromVessel } from '../context/DealSheet/utils/getPartialDealSheetUpdateFromVessel';
import { ModalError } from '../types';
import { BoatyardDealSheetDefaults, useBoatyard } from './useBoatyard';

const filterTruthy = (defaults: Partial<BoatyardDealSheetDefaults>) => {
  const filtered: Partial<BoatyardDealSheetDefaults> = {};
  Object.keys(defaults).forEach((key) => {
    const value = defaults[key as keyof BoatyardDealSheetDefaults];
    // @ts-ignore
    if (value) filtered[key] = value;
  });
  return filtered;
};

export const useGetDealSheet = ({
  dealer,
  vessel,
  dealSheetId,
}: {
  dealer: DealerInterface;
  vessel?: InventoryVesselFull;
  dealSheetId?: string;
}) => {
  const [state, setState] = useState<{
    dealSheet?: DealSheet;
    error?: ModalError;
    isBusy?: boolean;
  }>({});
  const [defaults, setDefaults] = useState<BoatyardDealSheetDefaults>();
  const { tenant } = useAppData();
  const boatyard = useBoatyard();

  if (
    boatyard?.vessel?.defaults &&
    !isEqual(defaults, boatyard.vessel.defaults)
  ) {
    setState({ dealSheet: undefined, error: undefined });
    setDefaults(boatyard.vessel.defaults);
  }

  useEffect(() => {
    if (
      (!defaults && !dealSheetId) ||
      state.isBusy ||
      state.dealSheet ||
      state.error
    ) {
      return;
    }

    setState({ dealSheet: undefined, error: undefined, isBusy: true });

    (async () => {
      const country = tenant.country as Country;
      const tenantDealSheetProps = defaults
        ? getPartialDealSheetUpdateFromTenant({
            tenant,
            condition: defaults.condition,
            vesselClass: defaults.vesselClass,
          })
        : null;
      const dealerDealSheetProps = getPartialDealSheetUpdateFromDealer(dealer);
      const hasLength =
        !!defaults?.vesselLength && !!defaults?.vesselLengthUnit;
      let vesselDealSheetProps: Partial<DealSheet> = {};
      if (vessel) {
        vesselDealSheetProps = getPartialDealSheetUpdateFromVessel(vessel);
      }

      const dealerFees = [
        ...getApplicableFees({
          vertical: tenant.vertical,
          dealer,
          vessel,
          condition: defaults?.condition,
        }),
        ...(defaults?.dealerFees?.map((fee, index) => {
          return { ...fee, id: index.toString() };
        }) || []),
      ];

      const partialDealSheet: Partial<DealSheet> = {
        ...dealerDealSheetProps,
        ...tenantDealSheetProps,
        ...(dealSheetId ? { id: dealSheetId } : null),
        ...(filterTruthy(
          pick(
            defaults,
            'imageUrl',
            'financeType',
            'term',
            'downPayment',
            'creditRating',
            'additionalLeadData'
          )
        ) as Partial<DealSheet>),
        returnWebsiteUrl: defaults?.returnWebsiteUrl,
        ...(vesselDealSheetProps.inventoryId
          ? vesselDealSheetProps
          : {
              ...pick(
                defaults,
                'dealerId',
                'year',
                'make',
                'model',
                'condition',
                'dealerPrice',
                'vesselClass',
                'engineManufacturer'
              ),
              ...(filterTruthy(
                pick(
                  defaults,
                  'totalEngines',
                  'totalHorsepower',
                  'mileage',
                  'hin',
                  'stockNumber'
                )
              ) as Partial<DealSheet>),
              externalInventoryId: defaults?.customInventoryId,
              dmSellingPrice: defaults?.dealerPrice,
              msrp: defaults?.basePrice,
              retailPrice: defaults?.basePrice,
              salesPrice: defaults?.dealerPrice,
              totalMsrp: defaults?.basePrice,
              vesselLength: {
                value: (hasLength && defaults?.vesselLength) || null,
                unit: (hasLength && defaults?.vesselLengthUnit) || null,
              },
            }),
        dealerFees,
        country,
        discounts: [],
        estimatedPayment: null,
        salesTax: 0,
        oemCodes: defaults?.oemCode,
        customModelIncentives: defaults?.customModelIncentives,
      };

      // If vessel data did not come from inventory service,
      // find make and model ID by name
      if (!partialDealSheet.id && !partialDealSheet.inventoryId) {
        const associationsRes = await getVesselAssociations({
          vertical: tenant.vertical,
          year: defaults?.year as number,
          make: defaults?.make as string,
          model: defaults?.model as string,
          oemCode: defaults?.oemCode as string,
          ...(defaults?.engineManufacturer
            ? { engineManufacturer: defaults.engineManufacturer }
            : null),
        });
        if (associationsRes.type === STATUS_SUCCESS) {
          const { makeId, modelId, oemCode, engineManufacturerId } =
            associationsRes.value;
          if (makeId) partialDealSheet.makeId = makeId;
          if (modelId) partialDealSheet.modelId = modelId;
          if (oemCode) partialDealSheet.oemCodes = oemCode;
          if (engineManufacturerId) {
            partialDealSheet.engineManufacturerId = engineManufacturerId;
          }
        }
      }

      const dealSheet = await getOrCreateDealSheet(partialDealSheet);

      if (dealSheet) {
        setState({ dealSheet, isBusy: false });
      } else {
        setState({
          error: partialDealSheet.id
            ? ModalError.DEAL_NOT_FOUND
            : ModalError.REQUEST_UNAVAILABLE,
          isBusy: false,
        });
      }
    })();
  }, [dealer, dealSheetId, defaults, state, tenant, vessel]);

  return state;
};
