"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNIT_IMAGE_DEFAULT = exports.PHONE_HELPER_TEXT = exports.ISO_DATE_FORMAT = exports.DATE_FORMAT_SHORT = exports.DATE_FORMAT = exports.DEFAULT_FINANCE_REQUIREMENTS = exports.MAX_DOWN_PAYMENT = exports.RIDER_HEADER_HEIGHT = exports.RIDER_WIDTH = exports.NULL_UNIT_VALUE = exports.VESSEL_YEARS = exports.VESSEL_UNITS = exports.VESSEL_CLASSES = exports.FINANCE = exports.CASH = exports.USED = exports.NEW = exports.DEFAULT_TOKEN_INTERVAL_TIME = exports.DEFAULT_API_DEBOUNCE_TIME = exports.CLOUDINARY_CLOUDNAME = void 0;
var apis_1 = require("@digital-motors-boatyard/common/dist/apis");
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
exports.CLOUDINARY_CLOUDNAME = 'digital-motors-boatyard';
exports.DEFAULT_API_DEBOUNCE_TIME = 300;
exports.DEFAULT_TOKEN_INTERVAL_TIME = 240000;
exports.NEW = enums_1.Condition.NEW;
exports.USED = enums_1.Condition.USED;
exports.CASH = enums_1.FinanceType.CASH;
exports.FINANCE = enums_1.FinanceType.FINANCE;
exports.VESSEL_CLASSES = Object.values(apis_1.VesselClass);
exports.VESSEL_UNITS = Object.values(apis_1.LengthUnit);
exports.VESSEL_YEARS = Array.from(
// 1984 through Current Year + 1
{ length: new Date().getFullYear() - 1982 }, function (v, k) { return k + 1984; }).reverse();
exports.NULL_UNIT_VALUE = { unit: null, value: null };
exports.RIDER_WIDTH = 480;
exports.RIDER_HEADER_HEIGHT = 320;
// Represented as a percentage of dmSellingPrice
exports.MAX_DOWN_PAYMENT = 80;
exports.DEFAULT_FINANCE_REQUIREMENTS = {
    minimumAmountFinanced: 0,
    maximumAmountFinanced: Infinity,
    maxAdvance: null,
};
exports.DATE_FORMAT = 'MM / dd / yyyy';
exports.DATE_FORMAT_SHORT = 'MM / yyyy';
exports.ISO_DATE_FORMAT = 'yyyy-MM-dd';
exports.PHONE_HELPER_TEXT = 'By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply.';
exports.UNIT_IMAGE_DEFAULT = 'https://res.cloudinary.com/digital-motors-boatyard/defaults/unitImageDefault.png';
