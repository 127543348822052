"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINANCE_PLUS_CREDIT_APP_OPTIONAL_DISCLOSURES = exports.FINANCE_PLUS_CREDIT_APP_CONSENT_DISCLOSURES = exports.FINANCE_PLUS_CREDIT_APP_DISCLOSURES = exports.CUSTOMER_TYPES = exports.INDIVIDUAL_VALUE = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
exports.INDIVIDUAL_VALUE = 'Individual';
exports.CUSTOMER_TYPES = [exports.INDIVIDUAL_VALUE, 'Business'];
exports.FINANCE_PLUS_CREDIT_APP_DISCLOSURES = [
    {
        locale: enums_1.Locale.AMERICAN_ENGLISH,
        name: 'Finance Plus Credit App',
        description: "<p>By submitting this request, you represent and warrant that you are the person identified above, or, if the person or entity identified above is not an individual, you represent and warrant that you are authorized to submit this request on behalf of such person or entity.</p>",
        priority: 0,
        type: [enums_1.DisclosureType.NEW_FINANCE, enums_1.DisclosureType.PREOWNED_FINANCE],
        location: [enums_1.DisclosureLocation.CREDIT_APPLICATION],
    },
];
exports.FINANCE_PLUS_CREDIT_APP_CONSENT_DISCLOSURES = [
    {
        locale: enums_1.Locale.AMERICAN_ENGLISH,
        name: 'Finance Plus Credit App Consent (1)',
        description: "<p>By submitting this request, you (i) agree that CNH (New Holland Construction), CNH Capital (defined below) and the dealer(s) you selected may share information with each other regarding your prequalification request, (ii) agree to be contacted about your prequalification request by the parties described in clause (i) above, and (iii) agree to the CNH Capital Privacy Notice.</p><br/><br/>",
        priority: 0,
        type: [enums_1.DisclosureType.NEW_FINANCE, enums_1.DisclosureType.PREOWNED_FINANCE],
        location: [enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION],
    },
    {
        locale: enums_1.Locale.AMERICAN_ENGLISH,
        name: 'Finance Plus Credit App Consent (2)',
        description: "<p>By submitting this request, you also expressly authorize CNH Industrial Capital America LLC or CNH Industrial Capital Canada Ltd. (individually, and collectively, \u201CCNH Capital\u201D) to investigate the credit worthiness of, and to verify the identity of, the person or entity listed above, including without limitation, by making soft inquiries with one or more consumer credit bureaus to obtain credit information about you to pre-qualify you (if the person identified above is an individual), or by obtaining commercial credit reports from commercial credit reporting agencies to prequalify the person or entity identified above (if the person or entity identified above is not an individual), and to share such information with credit reporting agencies, identity verification service providers, New Holland equipment dealers and subsidiaries and affiliates of CNH Capital in connection with this pre-qualification request.\n<br/>\n<br/>\nPlease note that soft credit inquiries with consumer credit bureaus will not impact your credit score, but if you subsequently apply for financing with CNH Capital, CNH Capital may make a hard credit inquiry at that time that could impact your credit score.</p>><br/><br/>",
        priority: 0,
        type: [enums_1.DisclosureType.NEW_FINANCE, enums_1.DisclosureType.PREOWNED_FINANCE],
        location: [enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION],
    },
];
exports.FINANCE_PLUS_CREDIT_APP_OPTIONAL_DISCLOSURES = [
    {
        locale: enums_1.Locale.AMERICAN_ENGLISH,
        name: 'optInOut1',
        description: "<p>Indicate if you would like to receive more information from CNH Capital (Financial Services) about products and services via email, phone or text. Once signed up, you will have the option to opt out from such communications at any time.</p>",
        priority: 0,
        type: [enums_1.DisclosureType.NEW_FINANCE, enums_1.DisclosureType.PREOWNED_FINANCE],
        location: [enums_1.DisclosureLocation.CONSENT_CREDIT_APPLICATION],
    },
];
