import {
  DealerInterface,
  DealSheet,
} from '@digital-motors-boatyard/common/dist/interfaces';

export const getPartialDealSheetUpdateFromDealer = (
  dealer?: DealerInterface | null
): Partial<DealSheet> => {
  if (!dealer) return {};
  return {
    dealerId: dealer.id,
    dealerName: dealer.name,
    externalDealerId: dealer.providerDealerId,
    showTitleFees: !!dealer.showTitleFees,
    showRegistrationFees: !!dealer.showRegistrationFees,
  };
};
