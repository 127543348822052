"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalculateTaxValues = exports.calculateTaxValues = void 0;
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var react_1 = require("react");
var constants_1 = require("../../../constants");
var DealSheet_1 = require("../../DealSheet");
var NO_TAX = {
    taxableSum: 0,
    calculatedTax: 0,
};
var calculateTaxValues = function (_a) {
    var financeType = _a.financeType, taxRate = _a.taxRate, taxAttributes = _a.taxAttributes, basePrice = _a.basePrice, fniProductsSum = _a.fniProductsSum, accessoriesSum = _a.accessoriesSum, dealerFeesSum = _a.dealerFeesSum, incentivesSum = _a.incentivesSum, calculatedDownPayment = _a.calculatedDownPayment, netTradeInCredit = _a.netTradeInCredit;
    if (!basePrice)
        return NO_TAX;
    var _b = taxAttributes || {}, _c = _b.salesTaxInEffect, salesTaxInEffect = _c === void 0 ? true : _c, _d = _b.dealerFeesTaxable, dealerFeesTaxable = _d === void 0 ? true : _d, _e = _b.downPaymentTaxable, downPaymentTaxable = _e === void 0 ? false : _e, _f = _b.incentivesTaxable, incentivesTaxable = _f === void 0 ? false : _f, _g = _b.netTradeInCreditTaxable, netTradeInCreditTaxable = _g === void 0 ? false : _g;
    var taxableSum = (0, math_utilities_1.calculateArraySum)([
        basePrice,
        fniProductsSum,
        accessoriesSum,
        dealerFeesTaxable ? dealerFeesSum : 0,
        !incentivesTaxable && !!incentivesSum ? -incentivesSum : 0,
        !netTradeInCreditTaxable && netTradeInCredit > 0 ? -netTradeInCredit : 0,
        !downPaymentTaxable && financeType === constants_1.FINANCE && !!calculatedDownPayment
            ? -calculatedDownPayment
            : 0,
    ]);
    var calculatedTax = 0;
    if (salesTaxInEffect) {
        calculatedTax = taxRate ? Math.max(0, taxableSum) * taxRate : 0;
    }
    return {
        taxableSum: Math.max(0, (0, math_utilities_1.preciseNumber)(taxableSum, 2)),
        calculatedTax: (0, math_utilities_1.preciseNumber)(calculatedTax, 2),
    };
};
exports.calculateTaxValues = calculateTaxValues;
var useCalculateTaxValues = function (params) {
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    return (0, react_1.useMemo)(function () {
        return (0, exports.calculateTaxValues)(__assign(__assign({}, params), { calculatedDownPayment: Number(params.calculatedDownPayment) || 0, financeType: dealSheet.financeType }));
    }, [dealSheet.financeType, params]);
};
exports.useCalculateTaxValues = useCalculateTaxValues;
