"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreditAppProvider = void 0;
var interfaces_1 = require("@digital-motors-boatyard/common/dist/interfaces");
var react_1 = require("react");
var Dealer_1 = require("../context/Dealer");
var DealSheet_1 = require("../context/DealSheet");
var Tenant_1 = require("../context/Tenant");
var useCreditAppProvider = function () {
    var tenant = (0, Tenant_1.useTenant)();
    var dealer = (0, Dealer_1.useDealer)();
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    return (0, react_1.useMemo)(function () {
        var _a;
        var providerConfig = ((_a = tenant.lenders) === null || _a === void 0 ? void 0 : _a.dealerPriority)
            ? dealer.creditAppProviderConfig
            : tenant.creditAppProviderConfig;
        var creditAppProvider = providerConfig === null || providerConfig === void 0 ? void 0 : providerConfig.creditAppProvider;
        if (!creditAppProvider) {
            return null;
        }
        var providerStatusActive = !providerConfig.providerDealerStatus ||
            providerConfig.providerDealerStatus === interfaces_1.ProviderDealerStatus.ACTIVE;
        var providerSupportsMake = !providerConfig.supportedMakeIds ||
            (dealSheet.makeId &&
                providerConfig.supportedMakeIds.includes(dealSheet.makeId));
        return providerStatusActive && providerSupportsMake
            ? creditAppProvider
            : null;
    }, [tenant, dealer, dealSheet.makeId]);
};
exports.useCreditAppProvider = useCreditAppProvider;
