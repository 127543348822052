import { VesselClass } from '@digital-motors-boatyard/common/dist/apis';
import {
  Condition,
  Country,
  Currency,
  FinanceType,
} from '@digital-motors-boatyard/common/dist/enums';
import {
  DealSheet,
  TenantInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import {
  getDefaultPaymentType,
  getLoanDefaults,
} from '@digital-motors-boatyard/common-frontend/dist/utility/pricing';

type RequiredDealSheetParams = Pick<
  DealSheet,
  'tenantId' | 'tenantName' | 'country' | 'currency'
>;

interface Params {
  condition: Condition | null;
  vesselClass: VesselClass;
  tenant: TenantInterface;
}

export const getPartialDealSheetUpdateFromTenant = ({
  condition,
  vesselClass,
  tenant,
}: Params): RequiredDealSheetParams & Partial<DealSheet> => {
  const requiredParams = {
    tenantId: tenant.id,
    tenantName: tenant.name,
    country: tenant.country as Country,
    currency: tenant.currency as Currency,
  };

  if (!tenant.configuration?.payments) return requiredParams;

  const financeType = getDefaultPaymentType(tenant, condition);

  return {
    financeType,
    ...requiredParams,
    ...(financeType === FinanceType.FINANCE
      ? getLoanDefaults({
          condition,
          vesselClass,
          tenant,
        })
      : null),
  };
};
