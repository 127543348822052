"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlButton = exports.ApprovedWrapper = exports.ContentWrapper = exports.HeaderWrapper = exports.ControlWrapper = exports.Paragraph = exports.SectionHeader = exports.SectionWrapper = exports.LayoutWrapper = void 0;
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var color_1 = require("../../../lib/color");
var PillButton_1 = require("../../PillButton");
exports.LayoutWrapper = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
exports.SectionWrapper = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  text-align: center;\n  padding: ", ";\n  background-color: ", ";\n"], ["\n  display: block;\n  text-align: center;\n  padding: ", ";\n  background-color: ", ";\n"])), ui_theme_1.spacing.xs, function (props) {
    return props.highlight ? (0, color_1.color)('bg05') : 'transparent';
});
exports.SectionHeader = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: ", ";\n"], ["\n  display: block;\n  margin-bottom: ", ";\n"])), ui_theme_1.spacing.xxs);
exports.SectionHeader.defaultProps = {
    bold: true,
    variant: 'lead',
};
exports.Paragraph = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  margin: ", " 0;\n"], ["\n  display: block;\n  margin: ", " 0;\n"])), ui_theme_1.spacing.xxs);
exports.Paragraph.defaultProps = {
    variant: 'small',
};
exports.ControlWrapper = ui_theme_1.styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  margin: ", " ", " 0;\n  justify-content: center;\n"], ["\n  display: block;\n  margin: ", " ", " 0;\n  justify-content: center;\n"])), ui_theme_1.spacing.xs, ui_theme_1.spacing.xxs);
exports.HeaderWrapper = ui_theme_1.styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: 28px;\n  letter-spacing: 0.32px;\n  color: #343434;\n  text-align: center;\n  line-height: 37px;\n"], ["\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: 28px;\n  letter-spacing: 0.32px;\n  color: #343434;\n  text-align: center;\n  line-height: 37px;\n"])));
exports.ContentWrapper = ui_theme_1.styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-rendering: optimizeLegibility;\n  font-feature-settings: 'kern' 1;\n  -webkit-font-feature-settings: 'kern';\n  -moz-font-feature-settings: 'kern';\n  -moz-font-feature-settings: 'kern=1';\n  font-kerning: normal;\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: 14px;\n  color: #343434;\n  text-align: center;\n  line-height: 20px;\n"], ["\n  text-rendering: optimizeLegibility;\n  font-feature-settings: 'kern' 1;\n  -webkit-font-feature-settings: 'kern';\n  -moz-font-feature-settings: 'kern';\n  -moz-font-feature-settings: 'kern=1';\n  font-kerning: normal;\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: 14px;\n  color: #343434;\n  text-align: center;\n  line-height: 20px;\n"])));
exports.ApprovedWrapper = ui_theme_1.styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-decoration: underline;\n  font-family: 'Poppins Bold', 'Poppins', sans-serif;\n  font-weight: 700;\n  color: #53ba61;\n"], ["\n  text-decoration: underline;\n  font-family: 'Poppins Bold', 'Poppins', sans-serif;\n  font-weight: 700;\n  color: #53ba61;\n"])));
exports.ControlButton = (0, ui_theme_1.styled)(PillButton_1.PillButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
