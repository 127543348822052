import axiosInstance from '@digital-motors-boatyard/api-sdk/dist/axiosInstance';
import {
  RiderComponent,
  RiderComponentProvider,
  SignInUser,
  UserData,
  VesselProvider,
} from '@digital-motors-boatyard/by-vessel-rider.component';
import { InventoryVesselFull } from '@digital-motors-boatyard/common/dist';
import {
  Condition,
  DisclosureLocation,
  FunnelStep,
  ProviderLogo,
} from '@digital-motors-boatyard/common/dist/enums';
import {
  DealerInterface,
  DealSheet,
  DealSheetSummary,
  TenantInterface,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import { useInterval } from '@digital-motors-boatyard/common-frontend/dist/hooks';
import { getIntlPhone } from '@digital-motors-boatyard/common-frontend/dist/utility/getIntlPhone';
import {
  theme as defaultTheme,
  ThemeProvider,
} from '@digital-motors-boatyard/ui.theme';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import shortid from 'shortid';

import { createAnonymousUser } from './api/createAnonymousUser';
import { getDealer } from './api/getDealer';
import { getDealSheetSummary } from './api/getDealSheetSummary';
import { getTenant } from './api/getTenant';
import { getVessel } from './api/getVessel';
import { refreshUserToken } from './api/refreshUserToken';
import { sendPhoneValidation } from './api/sendPhoneValidation';
import { AggregatedDisclosure } from './components/AggregatedDisclosure';
import { ErrorModal } from './components/ErrorModal';
import { Header } from './components/Header';
import { PoweredBy } from './components/PoweredBy';
import { ReEntryModal } from './components/re-entry/ReEntryModal';
import { UserAuthModal } from './components/user-auth/UserAuthModal';
import { RiderLoading } from './components/vessel-rider/RiderLoading';
import { Widgets } from './components/widget/Widgets';
import {
  EMOTION_CACHE_KEY,
  LEAD_CALLBACK_DEAL_PROPS,
  LEAD_CALLBACK_USER_PROPS,
  TENANT_PROVIDER_DATA,
} from './constants';
import { AnalyticsProvider, useAnalytics } from './context/Analytics';
import { AppDataProvider, useAppData } from './context/AppDataContext';
import { User, UserProvider, useUser } from './context/User';
import { getUserFromJwt } from './context/User/utils/getUserFromJwt';
import { setAxiosAuthorizationHeader } from './context/User/utils/setAxiosAuthorizationHeader';
import { useBeforeMount } from './hooks/useBeforeMount';
import { useBoatyard } from './hooks/useBoatyard';
import { useGetDealSheet } from './hooks/useGetDealSheet';
import { useStorage } from './hooks/useStorage';
import { extraScopeStylisPlugin } from './lib/extraScopeStylisPlugin';
import { getThemeOverrides } from './lib/getThemeOverrides';
import {
  Disclaimer,
  FontStyles,
  GlobalStyles,
  SiteOverlay,
  VesselRiderContainer,
} from './styles';
import { ModalError } from './types';

interface AppEntry {
  tenantId: string;
  dealerId: string;
  dealSheetId: string;
}

interface ContentProps {
  isOpen: boolean;
  dealSummary?: DealSheetSummary;
}

const AppInnerContent: FC<ContentProps> = ({ isOpen, dealSummary }) => {
  const storage = useStorage();
  const boatyard = useBoatyard();
  const { trackEvent } = useAnalytics();
  const { tenant, dealer } = useAppData();
  const { id: tenantId, country, configuration } = tenant;
  const user = useUser();
  const { isRegistered, authModalKey, setAuthModalView, setSignInPhone } = user;
  const signInUser: SignInUser = useCallback(
    ({ callback, userPhoneNumber } = {}) => {
      if (userPhoneNumber) {
        sendPhoneValidation({
          phoneNumber: userPhoneNumber,
          tenantId,
          country,
        });
        setSignInPhone(userPhoneNumber);
        setAuthModalView('verify', callback);
      } else {
        setAuthModalView('signin', callback);
      }
    },
    [tenantId, country, setAuthModalView, setSignInPhone]
  );
  const [vessel, setVessel] = useState<InventoryVesselFull>();

  useEffect(() => {
    (async () => {
      const vesselIdentifier =
        boatyard?.vessel?.defaults.hin ||
        boatyard?.vessel?.defaults.stockNumber;
      if (
        tenant.configuration?.web.global.checkoutRiderInventoryAwareness &&
        vesselIdentifier
      ) {
        const vesselRes = await getVessel(String(vesselIdentifier), dealer.id);
        if (vesselRes.type === STATUS_SUCCESS) {
          setVessel(vesselRes.value);
        }
      }
    })();
  }, [
    tenant.configuration?.web.global.checkoutRiderInventoryAwareness,
    boatyard?.vessel?.defaults,
    dealer.id,
  ]);

  const { dealSheet, error } = useGetDealSheet({
    dealer,
    vessel,
    dealSheetId: dealSummary?.id,
  });
  const token = storage.get(`token__${tenantId}`);
  const userData: UserData = useMemo(
    () => ({
      id: user.id,
      isRegistered,
      token,
    }),
    [user.id, isRegistered, token]
  );
  const initialView =
    dealSheet?.isSubmitted || dealSheet?.funnelStep === FunnelStep.NEXT_STEPS
      ? 'quote'
      : boatyard?.reEntry?.[1] || 'deal';

  const salesPortalDomain = useMemo(() => {
    return configuration?.providerLogo
      ? TENANT_PROVIDER_DATA[configuration.providerLogo].salesPortalDomain
      : TENANT_PROVIDER_DATA[ProviderLogo.NONE].salesPortalDomain;
  }, [configuration]);

  const leadCallback = useCallback(
    (dealSheet: DealSheet) => {
      if (boatyard?.leadCallback) {
        const additionalData = dealSheet.additionalLeadData?.reduce(
          (result, data) => ({ ...result, [data.name]: data.value }),
          {}
        );
        boatyard.leadCallback({
          user: pick(user, LEAD_CALLBACK_USER_PROPS),
          deal: pick(dealSheet, LEAD_CALLBACK_DEAL_PROPS),
          url: `https://${salesPortalDomain}/deal-summary/${dealSheet.id}`,
          additionalData,
        });
      }
    },
    [boatyard, user, salesPortalDomain]
  );

  const closeRider = useCallback(() => {
    if (dealSummary?.id && dealSheet?.returnWebsiteUrl) {
      // return to given source URL when present on a re-entry
      window.location.href = dealSheet.returnWebsiteUrl;
    } else {
      boatyard?.closeRider();
    }
  }, [boatyard, dealSheet, dealSummary]);

  const locked =
    boatyard?.vessel?.defaults.condition === Condition.NEW
      ? !isRegistered &&
        !!configuration?.web.vdp.requiresAuthenticationToUnlockNewPricing
      : !isRegistered &&
        !!configuration?.web.vdp.requiresAuthenticationToUnlockPreownedPricing;

  const showApp = tenant && dealer && dealSheet && !error;

  return showApp ? (
    <VesselProvider vessel={vessel ?? ({} as InventoryVesselFull)}>
      <RiderComponentProvider
        userData={userData}
        initialRiderView={initialView}
        showDealerInfo={true}
      >
        <VesselRiderContainer id="boatyard-rider-container" isOpen={isOpen}>
          <Header hideCloseButton={!!dealSummary} signInUser={signInUser} />
          <RiderComponent
            tenant={tenant}
            dealer={dealer}
            dealSheet={dealSheet}
            locked={locked}
            trackAnalyticsEvent={trackEvent}
            signInUser={signInUser}
            onClose={closeRider}
            onDone={closeRider}
            leadCallback={leadCallback}
          />
          <PoweredBy />
          <Disclaimer data-testid="VesselRider__Disclaimer">
            <AggregatedDisclosure
              location={DisclosureLocation.CHECKOUT_RIDER}
            />
          </Disclaimer>
        </VesselRiderContainer>
        <UserAuthModal key={authModalKey} />
      </RiderComponentProvider>
    </VesselProvider>
  ) : (
    <ThemeProvider theme={defaultTheme}>
      {error ? (
        <ErrorModal error={error} dismiss={() => boatyard?.closeRider()} />
      ) : (
        <RiderLoading isOpen={isOpen} />
      )}
    </ThemeProvider>
  );
};

const AppOuterContent: FC<ContentProps> = ({ isOpen, dealSummary }) => {
  const storage = useStorage();
  const renderKey = useRef(shortid.generate());
  const { tenant } = useAppData();
  const { id: tenantId, configuration } = tenant;
  const { isAuthenticated, isRegistered } = useUser();
  const previousIsRegistered = useRef(isRegistered);
  const [isReEntry, setIsReEntry] = useState(!!dealSummary && !isRegistered);
  const onDismiss = useCallback(() => setIsReEntry(false), []);
  const token = storage.get(`token__${tenantId}`);
  const canViewRider =
    // Does the user object have a valid token?
    isAuthenticated &&
    // Is the user registered (not anonymous)?
    (isRegistered ||
      // Does the tenant allow anonymous users, and this isn't a re-entry?
      (!configuration?.web.vdp.requiresAuthentication && !dealSummary));

  // Make sure the correct bearer token is registered with Axios
  if (canViewRider) {
    setAxiosAuthorizationHeader(token);
  }

  // Reset the render key when the user is logged out to ensure a new deal
  // is created for the now current user
  useEffect(() => {
    if (previousIsRegistered.current && !isRegistered) {
      renderKey.current = shortid.generate();
    }
    previousIsRegistered.current = isRegistered;
  }, [isRegistered]);

  return (
    <SiteOverlay key={renderKey.current} isOpen={isOpen || !!dealSummary}>
      {canViewRider ? (
        <AppInnerContent dealSummary={dealSummary} isOpen={isOpen} />
      ) : dealSummary ? (
        <ReEntryModal
          dealSummary={dealSummary}
          onDismiss={onDismiss}
          isOpen={isReEntry}
        />
      ) : (
        <UserAuthModal autoRegister />
      )}
    </SiteOverlay>
  );
};

const emotionCache = createCache({
  key: EMOTION_CACHE_KEY,
  stylisPlugins: [
    extraScopeStylisPlugin({
      scope: '#boatyard-rider',
      cacheKey: EMOTION_CACHE_KEY,
    }),
  ],
});

const App: FC = () => {
  const [entry, setEntry] = useState<AppEntry>({
    tenantId: '',
    dealerId: '',
    dealSheetId: '',
  });
  const [storeData, setStoreData] = useState<{
    tenant: TenantInterface | null;
    dealer: DealerInterface | null;
  }>({ tenant: null, dealer: null });
  const { tenant, dealer } = storeData;
  const [dealSummary, setDealSummary] = useState<DealSheetSummary>();
  const [modalError, setModalError] = useState<ModalError | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef(isOpen);
  const storage = useStorage();
  const boatyard = useBoatyard();
  const [isFetchingToken, setIsFetchingToken] = useState(true);

  const initialUserData: Partial<User> | null = useMemo(() => {
    if (isFetchingToken || !tenant) return null;

    const token = storage.get(`token__${tenant.id}`);
    const requiresAuth = !!tenant.configuration?.web.vdp.requiresAuthentication;
    const hasVesselUser = !!boatyard?.vessel?.user?.phoneNumber;

    // Check for existing user data in the jwt
    if (token) {
      const jwtUser = getUserFromJwt(token);
      const isAuthenticated =
        !!jwtUser?.phoneNumber && !!jwtUser?.isAuthenticated;

      if (
        jwtUser?.tenantId === tenant.id &&
        (isAuthenticated || (!requiresAuth && !hasVesselUser))
      ) {
        return jwtUser;
      }
    }
    // Check for pre-defined user data in boatyard data
    const userData = { ...boatyard?.vessel?.user };
    if (userData.phoneNumber) {
      // Strip out invalid characters
      let phone = userData.phoneNumber.replace(/[^\d+]/g, '');
      // Add intl prefix if it doesn't exist already
      if (!phone.match(/^\+\d/)) {
        phone = getIntlPhone(tenant?.country, phone.replace(/\D/g, ''));
      }
      userData.phoneNumber = phone;
      return userData;
    }
    // Otherwise there is no user data
    return null;
  }, [boatyard, isFetchingToken, storage, tenant]);

  useEffect(() => {
    let tenantId = `${entry.tenantId}`;
    let dealerId = `${entry.dealerId}`;

    (async () => {
      if (entry.dealSheetId) {
        const dealRes = await getDealSheetSummary(entry.dealSheetId);

        if (dealRes.type === STATUS_SUCCESS) {
          setDealSummary(dealRes.value);
          tenantId = `${dealRes.value.tenantId}`;
          dealerId = `${dealRes.value.dealerId}`;
        } else {
          setModalError(ModalError.DEAL_NOT_FOUND);
          return;
        }
      }
      if (tenantId && dealerId) {
        const [tenantRes, dealerRes] = await Promise.all([
          getTenant(tenantId),
          getDealer(dealerId),
        ]);

        if (
          tenantRes.type === STATUS_SUCCESS &&
          dealerRes.type === STATUS_SUCCESS
        ) {
          const initialRefreshToken = storage.get(`refreshToken__${tenantId}`);
          let hasToken = false;

          // Clear the existing token to avoid an expired/bad state
          storage.delete(`token__${tenantId}`);

          if (initialRefreshToken) {
            // Try and restart existing user session
            const tokenRes = await refreshUserToken({
              refreshToken: initialRefreshToken,
            });

            if (tokenRes.type === STATUS_SUCCESS) {
              const jwtUser = getUserFromJwt(tokenRes.value.token);

              // Check if the user token matches the tenant
              if (jwtUser?.tenantId === tenantRes.value.id) {
                hasToken = true;
                // Store the tokens
                storage.set(`token__${tenantId}`, tokenRes.value.token);
                storage.set(
                  `refreshToken__${tenantId}`,
                  tokenRes.value.refreshToken,
                  true
                );
              } else {
                storage.delete(`refreshToken__${tenantId}`);
              }
            } else {
              storage.delete(`refreshToken__${tenantId}`);
            }
          }
          // If the tenant does not require authentication, and this is
          // not a re-entry, create an anonymous user to start the deal
          if (
            !hasToken &&
            !entry.dealSheetId &&
            !boatyard?.vessel?.user?.phoneNumber &&
            !tenantRes.value.configuration?.web.vdp.requiresAuthentication
          ) {
            const userRes = await createAnonymousUser(tenantId);
            if (userRes.type === STATUS_SUCCESS) {
              storage.set(`token__${tenantId}`, userRes.value.token);
              storage.set(
                `refreshToken__${tenantId}`,
                userRes.value.refreshToken,
                true
              );
              setAxiosAuthorizationHeader(userRes.value.token);
            }
          }
          setIsFetchingToken(false);

          // Initialize the store data
          setStoreData({
            tenant: tenantRes.value,
            dealer: dealerRes.value,
          });
        } else {
          setModalError(ModalError.REQUEST_UNAVAILABLE);
        }
      }
    })();
  }, [boatyard, entry, storage]);

  useInterval(() => {
    if (!boatyard) return;
    const shouldOpen = !!boatyard.vessel || !!boatyard.reEntry;
    if (isOpenRef.current === shouldOpen) return;
    isOpenRef.current = shouldOpen;
    setIsOpen(shouldOpen);
  }, 200);

  useBeforeMount(() => {
    // Env vars injected by postBuild.js
    const envBaseUrl = '%API_PROXY%';
    if (!envBaseUrl.match(/^%/)) {
      axiosInstance.defaults.baseURL = envBaseUrl;
    }
  });

  const theme = useMemo(() => {
    if (!tenant) {
      return defaultTheme;
    }
    const baseTheme = tenant.theme || defaultTheme;
    const riderElement = document.getElementById('boatyard-rider');
    if (!riderElement) {
      return baseTheme;
    }
    const styles = getComputedStyle(riderElement);
    const themeOverrides = getThemeOverrides(styles);
    return merge(baseTheme, themeOverrides);
  }, [tenant]);

  const newEntry = {
    tenantId: '',
    dealerId: '',
    dealSheetId: '',
  };
  if (boatyard?.reEntry) {
    newEntry.dealSheetId = boatyard.reEntry[0];
  } else if (boatyard?.vessel) {
    newEntry.tenantId = boatyard.vessel.tenantId;
    newEntry.dealerId = boatyard.vessel.defaults.dealerId;
  }
  if (!isEqual(entry, newEntry)) {
    setEntry(newEntry);
  }

  const widgets = useRef(<Widgets />).current;

  if (modalError) {
    return (
      <>
        {widgets}
        <ThemeProvider theme={defaultTheme}>
          <EmotionCacheProvider value={emotionCache}>
            <GlobalStyles />
            <FontStyles />
            <ErrorModal
              error={modalError}
              dismiss={() => {
                setModalError(null);
                boatyard?.closeRider();
              }}
            />
          </EmotionCacheProvider>
        </ThemeProvider>
      </>
    );
  }

  if (!tenant || !dealer || isFetchingToken) return widgets;

  return (
    <>
      {widgets}
      <ThemeProvider theme={theme}>
        <EmotionCacheProvider value={emotionCache}>
          <GlobalStyles />
          <FontStyles />
          <AppDataProvider tenant={tenant} dealer={dealer}>
            <UserProvider initialData={initialUserData}>
              <AnalyticsProvider>
                <AppOuterContent isOpen={isOpen} dealSummary={dealSummary} />
              </AnalyticsProvider>
            </UserProvider>
          </AppDataProvider>
        </EmotionCacheProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
