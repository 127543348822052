"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePricingCalculations = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var pricing_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/pricing");
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var react_1 = require("react");
var constants_1 = require("../../../constants");
var DealSheet_1 = require("../../DealSheet");
var Locked_1 = require("../../Locked");
var calculateTaxValues_1 = require("./calculateTaxValues");
var usePricingCalculations = function (params) {
    var _a;
    var isLocked = (0, Locked_1.useIsLocked)();
    var _b = (0, DealSheet_1.useDealSheet)(), dealSheet = _b.dealSheet, loan = _b.loan;
    var condition = dealSheet.condition, totalMsrp = dealSheet.totalMsrp, dmSellingPrice = dealSheet.dmSellingPrice, retailPrice = dealSheet.retailPrice, fniProducts = dealSheet.fniProducts, reservation = dealSheet.reservation;
    var financeType = dealSheet.financeType;
    var dealSheetPricing = params.dealSheetPricing, hasFinanceData = params.hasFinanceData, _c = params.tradeInEstimate, tradeInEstimate = _c === void 0 ? dealSheet.tradeInEstimate : _c;
    var rebates = (0, react_1.useMemo)(function () { return (dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.rebates) || []; }, [dealSheetPricing]);
    var lenderRequirements = (dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.lenderRequirements) || constants_1.DEFAULT_FINANCE_REQUIREMENTS;
    var selectedOffer = !params.excludeSelectedOffer && ((_a = dealSheet.selectedOffer) === null || _a === void 0 ? void 0 : _a.offerId)
        ? dealSheet.selectedOffer
        : null;
    var taxRate = isLocked(condition) && !selectedOffer ? 0 : dealSheet.taxRate || 0;
    var reservationDeposit = (reservation === null || reservation === void 0 ? void 0 : reservation.status) === enums_1.ReservationTransactionStatus.SUCCESSFUL &&
        reservation.depositAmount
        ? reservation.depositAmount
        : 0;
    // Get sum calculations
    var fniProductsSum = (0, pricing_1.getFniProductsSum)(dealSheet);
    var accessoriesSum = (0, pricing_1.getAccessoriesSum)(dealSheet);
    var incentivesSum = (0, pricing_1.getIncentivesSum)(dealSheet);
    var titleFeesSum = (0, pricing_1.getTitleFeesSum)(dealSheet);
    var registrationFeesSum = (0, pricing_1.getRegistrationFeesSum)(dealSheet);
    var upgradesSum = (0, pricing_1.getUpgradesSum)(dealSheet);
    var dealerFeesSum = (0, pricing_1.getDealerFeesSum)(dealSheet);
    var netTradeInValue = (0, react_1.useMemo)(function () {
        if (!tradeInEstimate && !dealSheet.tradeInEstimate)
            return 0;
        var _a = tradeInEstimate || dealSheet.tradeInEstimate || {}, estimate = _a.estimate, payoffAmount = _a.payoffAmount;
        return (estimate || 0) - (payoffAmount || 0);
    }, [dealSheet.tradeInEstimate, tradeInEstimate]);
    var financeableSum = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        return (0, math_utilities_1.calculateArraySum)([
            dmSellingPrice,
            titleFeesSum,
            registrationFeesSum,
            fniProductsSum,
            accessoriesSum,
            dealerFeesSum,
        ]);
    }, [
        accessoriesSum,
        dmSellingPrice,
        dealerFeesSum,
        fniProductsSum,
        registrationFeesSum,
        titleFeesSum,
    ]);
    var dealSheetDownPayment = (0, react_1.useMemo)(function () {
        if (financeType === constants_1.FINANCE && loan) {
            if (selectedOffer)
                return selectedOffer.downPayment || 0;
            return loan.downPayment || 0;
        }
        return 0;
    }, [loan, financeType, selectedOffer]);
    var maxDownPaymentPercent = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        var _a = (lenderRequirements || {}).minimumAmountFinanced, minFinancing = _a === void 0 ? 0 : _a;
        var maxCredits = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
        var theoreticalMax = Math.floor(((financeableSum - maxCredits - minFinancing) / dmSellingPrice) * 100);
        return Math.max(0, Math.min(theoreticalMax, constants_1.MAX_DOWN_PAYMENT));
    }, [
        dmSellingPrice,
        financeableSum,
        lenderRequirements,
        netTradeInValue,
        incentivesSum,
    ]);
    var minDownPaymentPercent = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        var _a = (lenderRequirements || {}).maximumAmountFinanced, maxFinancing = _a === void 0 ? 0 : _a;
        var maxCredits = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
        var theoreticalMin = Math.ceil(((maxFinancing - financeableSum + maxCredits) / dmSellingPrice) * -100);
        return Math.max(0, theoreticalMin);
    }, [
        dmSellingPrice,
        financeableSum,
        lenderRequirements,
        netTradeInValue,
        incentivesSum,
    ]);
    var calculatedDownPayment = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        return Math.min(Math.floor((maxDownPaymentPercent / 100) * dmSellingPrice), dealSheetDownPayment);
    }, [dealSheetDownPayment, dmSellingPrice, maxDownPaymentPercent]);
    var _d = (0, calculateTaxValues_1.useCalculateTaxValues)({
        basePrice: isLocked(condition) && !selectedOffer ? totalMsrp : dmSellingPrice,
        taxRate: taxRate,
        fniProductsSum: fniProductsSum,
        accessoriesSum: accessoriesSum,
        dealerFeesSum: dealerFeesSum,
        incentivesSum: incentivesSum,
        netTradeInCredit: Math.max(netTradeInValue, 0),
        taxAttributes: dealSheet.salesTaxAttributes || null,
        calculatedDownPayment: calculatedDownPayment,
    }), taxableSum = _d.taxableSum, calculatedTax = _d.calculatedTax;
    var priceWithTax = (0, react_1.useMemo)(function () {
        return (0, math_utilities_1.preciseNumber)((0, math_utilities_1.calculateArraySum)([
            isLocked(condition) && !selectedOffer ? totalMsrp : dmSellingPrice,
            calculatedTax,
            fniProductsSum,
            accessoriesSum,
            dealerFeesSum,
            titleFeesSum,
            registrationFeesSum,
        ]));
    }, [
        accessoriesSum,
        calculatedTax,
        condition,
        dmSellingPrice,
        dealerFeesSum,
        fniProductsSum,
        isLocked,
        registrationFeesSum,
        selectedOffer,
        titleFeesSum,
        totalMsrp,
    ]);
    var cashBackBeforeTax = (0, react_1.useMemo)(function () {
        if (!netTradeInValue)
            return 0;
        switch (financeType) {
            case constants_1.FINANCE: {
                var loanCredit = (0, math_utilities_1.calculateArraySum)([
                    netTradeInValue,
                    incentivesSum,
                    calculatedDownPayment,
                ]);
                if (loanCredit > financeableSum) {
                    return loanCredit - financeableSum;
                }
                return 0;
            }
            case constants_1.CASH:
            default: {
                var cashCredit = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
                return cashCredit > priceWithTax ? cashCredit - priceWithTax : 0;
            }
        }
    }, [
        calculatedDownPayment,
        financeableSum,
        financeType,
        incentivesSum,
        netTradeInValue,
        priceWithTax,
    ]);
    var applicableTradeInValueBeforeTax = (0, react_1.useMemo)(function () {
        return netTradeInValue - cashBackBeforeTax;
    }, [cashBackBeforeTax, netTradeInValue]);
    var cashBack = (0, react_1.useMemo)(function () {
        if (!cashBackBeforeTax || cashBackBeforeTax <= 0)
            return 0;
        return Math.max(Math.round(cashBackBeforeTax) - Math.round(calculatedTax || 0), 0);
    }, [cashBackBeforeTax, calculatedTax]);
    var applicableTradeInValue = (0, react_1.useMemo)(function () {
        if (cashBack <= 0)
            return applicableTradeInValueBeforeTax;
        return (Math.round(applicableTradeInValueBeforeTax) +
            Math.round(calculatedTax || 0));
    }, [applicableTradeInValueBeforeTax, cashBack, calculatedTax]);
    var monthlyPayment = (0, react_1.useMemo)(function () {
        if (selectedOffer) {
            return selectedOffer.paymentAmount;
        }
        var discounts = dealSheet.discounts.filter(function (discount) { return discount.incentiveType == enums_1.IncentiveType.DISCOUNT; });
        var currentRebates = dealSheet.discounts.filter(function (discount) {
            return discount.incentiveType == enums_1.IncentiveType.REBATE &&
                !rebates.some(function (rebate) { return rebate.id === discount.incentiveId; });
        });
        return (0, pricing_1.getEstimatedMonthlyPayment)(__assign(__assign({}, dealSheet), { discounts: __spreadArray(__spreadArray(__spreadArray([], discounts, true), currentRebates, true), (isLocked(condition) ? [] : rebates), true), basePrice: condition === enums_1.Condition.USED
                ? dealSheet.retailPrice
                : dealSheet.totalMsrp, loan: loan, financeType: financeType, fniProducts: fniProducts, calculatedDownPayment: calculatedDownPayment, applicableTradeInValue: applicableTradeInValue, dealerFeesSum: dealerFeesSum, titleRegistrationFeesTotal: titleFeesSum + registrationFeesSum, taxRate: taxRate, lenderRequirements: lenderRequirements }));
    }, [
        applicableTradeInValue,
        calculatedDownPayment,
        condition,
        dealSheet,
        dealerFeesSum,
        financeType,
        fniProducts,
        isLocked,
        lenderRequirements,
        loan,
        rebates,
        registrationFeesSum,
        selectedOffer,
        taxRate,
        titleFeesSum,
    ]);
    var salesPrice = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return null;
        var price = (0, math_utilities_1.calculateSalePrice)({
            dealerPrice: dmSellingPrice,
            netTradeInEstimate: applicableTradeInValue,
            fniProductsSum: fniProductsSum,
            accessoriesSum: accessoriesSum,
            incentivesSum: incentivesSum,
            documentationFee: dealerFeesSum,
            downPayment: calculatedDownPayment,
            registrationFee: (0, math_utilities_1.calculateArraySum)([registrationFeesSum, titleFeesSum]),
        });
        return Math.max(0, price);
    }, [
        accessoriesSum,
        applicableTradeInValue,
        calculatedDownPayment,
        dmSellingPrice,
        dealerFeesSum,
        fniProductsSum,
        incentivesSum,
        registrationFeesSum,
        titleFeesSum,
    ]);
    var totalPrice = (0, react_1.useMemo)(function () {
        var priceWithTax = (0, math_utilities_1.calculateArraySum)([
            salesPrice || 0,
            calculatedTax || 0,
            -reservationDeposit || 0,
        ]);
        return (selectedOffer === null || selectedOffer === void 0 ? void 0 : selectedOffer.balanceAmount) || priceWithTax;
    }, [calculatedTax, salesPrice, selectedOffer, reservationDeposit]);
    var dealerDiscount = (0, react_1.useMemo)(function () {
        if (condition === constants_1.USED) {
            return (retailPrice || dmSellingPrice || 0) - (dmSellingPrice || 0);
        }
        return (totalMsrp || dmSellingPrice || 0) - (dmSellingPrice || 0);
    }, [condition, dmSellingPrice, retailPrice, totalMsrp]);
    return (0, react_1.useMemo)(function () {
        var amountDueAtSigning = financeType === constants_1.CASH
            ? totalPrice - cashBack
            : calculatedDownPayment - cashBack;
        var taxesAndFeesSum = (0, math_utilities_1.calculateArraySum)([
            calculatedTax,
            dealerFeesSum,
            registrationFeesSum,
            titleFeesSum,
        ]);
        return {
            accessoriesSum: accessoriesSum,
            amountDueAtSigning: Math.max(0, amountDueAtSigning),
            applicableTradeInValue: applicableTradeInValue,
            calculatedDownPayment: financeType === constants_1.FINANCE ? Math.max(0, calculatedDownPayment) : null,
            cashBack: cashBack,
            dealerDiscount: dealerDiscount,
            dealerFeesSum: dealerFeesSum,
            financeEnabled: hasFinanceData &&
                !!maxDownPaymentPercent &&
                minDownPaymentPercent <= maxDownPaymentPercent,
            fniProductsSum: fniProductsSum,
            incentivesSum: incentivesSum,
            maxDownPayment: Math.floor((dmSellingPrice || 0) * (maxDownPaymentPercent / 100)),
            minDownPayment: Math.ceil((dmSellingPrice || 0) * (minDownPaymentPercent / 100)),
            monthlyPayment: monthlyPayment,
            netTradeInValue: netTradeInValue,
            registrationFeesSum: registrationFeesSum,
            salesPrice: salesPrice,
            salesTax: calculatedTax,
            taxesAndFeesSum: taxesAndFeesSum,
            taxableSum: taxableSum,
            titleFeesSum: titleFeesSum,
            totalPrice: totalPrice,
            totalSavings: incentivesSum,
            upgradesSum: upgradesSum,
            reservationDeposit: reservationDeposit,
        };
    }, [
        accessoriesSum,
        applicableTradeInValue,
        calculatedDownPayment,
        calculatedTax,
        cashBack,
        dealerDiscount,
        dmSellingPrice,
        dealerFeesSum,
        financeType,
        fniProductsSum,
        hasFinanceData,
        incentivesSum,
        maxDownPaymentPercent,
        minDownPaymentPercent,
        monthlyPayment,
        netTradeInValue,
        registrationFeesSum,
        salesPrice,
        taxableSum,
        titleFeesSum,
        totalPrice,
        upgradesSum,
        reservationDeposit,
    ]);
};
exports.usePricingCalculations = usePricingCalculations;
